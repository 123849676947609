export const navLinks = [
  // { title: 'Home', link: '/' },

  { title: 'Pricing', link: '/pricing' },

  { title: 'Shop', link: '/shop' },

  { title: 'Best Deals', link: '/best-deals' },
  // { title: 'Contact us', link: '#contact' },

  // { title: 'Features', link: '#feature' },
  { title: 'Products', link: '/products' },
  { title: 'Return Policy', link: '/customer/return-policy' },
  { title: 'About us', link: '/about-us' },
];
