import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL;

// Define the GraphQL HTTP link
const httpLink = createHttpLink({
  uri: `${API_BASE_URL}/graphql`,
});

// Define the authentication link with API key and token
const authLink = setContext((_, { headers }) => {
  // Retrieve the token from local storage
  const token = localStorage.getItem('token');

  // Add the token and API key to the headers
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      'X-API-KEY': 'YmDf0sL8QsDgWqs', // Add the X-API-KEY header
    },
  };
});

// Define the error link to handle GraphQL and network errors
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      // Handle different types of GraphQL errors here
      if (err?.extensions?.code === 'UNAUTHENTICATED') {
        // Token is invalid or expired
        localStorage.removeItem('token');
        // Redirect to login page
        window.location.href = '/';
      }
    }
  }

  if (networkError) {
    localStorage.removeItem('token');
    // Redirect to login page
    window.location.href = '/';
    console.error(`[Network error]: ${networkError}`);
  }
});

// Combine all links into a single link chain
const link = ApolloLink.from([authLink, errorLink, httpLink]);

// Create the Apollo Client instance
const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

export default client;
