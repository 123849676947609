import React from 'react';
import {
  FaUser,
  FaCog,
  FaBell,
  FaSignOutAlt,
  FaBriefcase,
  FaChartLine,
  FaList,
} from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/authContext';
import { currentWebInterface } from '../store/reducers/main';

const HamburgerMenu = ({ onToggle, setShowHamburgerMenu }) => {
  const { webInterface } = useSelector((state) => state.main);
  const { userInfo } = useSelector((state) => state.user);

  const buttonText =
    webInterface === 'business' ? 'Switch to Business' : 'Switch to Customer';
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isAuthenticated, logout } = useAuth();

  const toggleWebInterface = () => {
    const switchedInterface =
      webInterface === 'business' ? 'customer' : 'business';
    dispatch(currentWebInterface(switchedInterface));
    navigate(switchedInterface === 'business' ? '/' : '/business/profile');
  };

  const handleMenuClick = (option) => {};

  return (
    <div
      className="fixed left-0 top-0 h-screen bg-white w-72 py-6 px-8 flex flex-col justify-start shadow-lg"
      style={{ backgroundColor: 'white', marginTop: 67, zIndex: 1 }}
    >
      {/* Profile Avatar */}
      <div className="flex flex-col items-center mb-8">
        <div className="bg-gray-200 rounded-full p-6 mb-2">
          <FaUser className="text-[#F16427] text-6xl" />
        </div>
        <div>
          <span className="text-sm font-small">{userInfo?.username}</span>
        </div>
      </div>
      <hr className="border-t border-gray-300 w-full mb-6" />
      {/* Menu Buttons */}
      <div className="space-y-6">
        {/* Example menu options with icons */}

        <button
          onClick={() => handleMenuClick('Profile')}
          className="flex items-center space-x-2 text-[#313131] text-lg font-poppins cursor-pointer w-full text-left"
        >
          <FaUser className="text-[#F16427] text-lg" />
          <span>Profile</span>
        </button>
        <button
          onClick={() => handleMenuClick('Settings')}
          className="flex items-center space-x-2 text-[#313131] text-lg font-poppins cursor-pointer w-full text-left"
        >
          <FaCog className="text-[#F16427] text-lg" />
          <span>Settings</span>
        </button>
        <button
          onClick={() => handleMenuClick('Notifications')}
          className="flex items-center space-x-2 text-[#313131] text-lg font-poppins cursor-pointer w-full text-left"
        >
          <FaBell className="text-[#F16427] text-lg" />
          <span>Notifications</span>
        </button>
        <button
          onClick={() => navigate('/business/analytics')}
          className="flex items-center space-x-2 text-[#313131] text-lg font-poppins cursor-pointer w-full text-left"
        >
          <FaChartLine className="text-[#F16427] text-lg" />{' '}
          {/* Assuming FaHandshake is the appropriate icon for "Switch to Seller" */}
          <span>Analytics</span>
        </button>
        <button
          onClick={() => navigate('/business/features')}
          className="flex items-center space-x-2 text-[#313131] text-lg font-poppins cursor-pointer w-full text-left"
        >
          <FaList className="text-[#F16427] text-lg" />{' '}
          {/* Assuming FaHandshake is the appropriate icon for "Switch to Seller" */}
          <span>Features</span>
        </button>
        <button
          onClick={toggleWebInterface}
          className="flex items-center space-x-2 text-[#313131] text-lg font-poppins cursor-pointer w-full text-left"
        >
          <FaBriefcase className="text-[#F16427] text-lg" />{' '}
          {/* Assuming FaHandshake is the appropriate icon for "Switch to Seller" */}
          <span>{buttonText}</span>
        </button>
        <button
          onClick={() => {
            setShowHamburgerMenu(false);
            logout();
          }}
          className="flex items-center space-x-2 text-[#313131] text-lg font-poppins cursor-pointer w-full text-left"
        >
          <FaSignOutAlt className="text-[#F16427] text-lg" />
          <span>Sign Out</span>
        </button>
      </div>
    </div>
  );
};

export default HamburgerMenu;
