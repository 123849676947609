import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import HeroShop from './HeroShop';
import Footer from './Footer';
import { BiSolidCartAdd } from 'react-icons/bi';
import { CiStar } from 'react-icons/ci';
import { FaRegEye, FaThumbsUp, FaShoppingBag, FaBoxes } from 'react-icons/fa';
import { AiOutlineDollar } from 'react-icons/ai';
import { GiCheckMark } from 'react-icons/gi';
import {
  CALCULATE_BUSINESSES_WITHIN_RANGE_QUERY,
  GET_PRODUCTS_BY_TAG,
} from '../GraphQL/mutation';
import client from '../GraphQL/ApolloClient';
import ShopHero from './ShopHero';
import { Link } from 'react-router-dom';
import Loader from '../common/Loader/Loader';
import { useSelector } from 'react-redux';

const Products = () => {
  const [products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [latitude, setLatitude] = useState(25.809098468643757);
  const [longitude, setLongitude] = useState(-80.25800041832925);
  const [loading, setLoading] = useState(true);
  const [businessInfo, setBusinessInfo] = useState([]);
  const { productTags } = useSelector((state) => state.product);

  useEffect(() => {
    // Function to get user location
    const getUserLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            // setLatitude(position.coords.latitude);
            // setLongitude(position.coords.longitude);
          },
          (error) => {
            console.error("Error fetching user's location:", error);
            // Use hardcoded values if location access is denied
            // setLatitude(24.9473143);
            // setLongitude(67.0535344);
          },
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
        // Use hardcoded values if geolocation is not supported
        // setLatitude(24.9473143);
        // setLongitude(67.0535344);
      }
    };

    getUserLocation();
  }, []);

  useEffect(() => {
    const fetchProductsByTag = async () => {
      const tags = productTags?.map((item) => item.tagId);
      try {
        const response = await client.query({
          query: GET_PRODUCTS_BY_TAG,
          variables: { tagIds: tags },
        });
      } catch (error) {
        console.error('Error fetching products by tag:', error);
      }
    };
    fetchProductsByTag();
  }, [productTags]);

  async function fetchBusinessesWithinRange(
    latitude,
    longitude,
    pageNo,
    pageSize,
  ) {
    setLoading(true); // Set loading to true when fetching starts

    const variables = { latitude, longitude, pageNo, pageSize };

    try {
      const response = await client.query({
        query: CALCULATE_BUSINESSES_WITHIN_RANGE_QUERY,
        variables,
      });
      setBusinessInfo(
        response?.data?.calculateBusinessesWithinRangeByLocation?.data
          ?.businesses,
      );
      const allProducts =
        response?.data?.calculateBusinessesWithinRangeByLocation?.data?.businesses?.flatMap(
          (business) =>
            business?.businessDetails?.inventoryList?.flatMap((inventory) =>
              inventory.products.map((product) => {
                return {
                  ...product,
                  businessId: business.businessId, // Add businessId to each product
                  category: inventory.category.categoryName,
                };
              }),
            ),
        );

      setProducts([allProducts[1]]);
      setFilteredProducts([allProducts[1]]);
      if (allProducts?.length > 0) setLoading(false); // Set loading to false after data is fetched

      return response.calculateBusinessesWithinRangeByLocation;
    } catch (error) {
      setLoading(false); // Set loading to false in case of error
      console.error('Error fetching businesses:', error);
      if (error.response) {
        console.error(
          'Error details:',
          error.response.errors || error.response,
        );
      } else {
        console.error('Network or other error:', error);
      }
      throw error;
    }
  }

  const callFunc = async () => {
    await fetchBusinessesWithinRange(latitude, longitude, pageNo, pageSize);
  };

  useEffect(() => {
    callFunc();
  }, [pageNo, pageSize]);

  const handleSearch = (value) => {
    if (value === '') {
      setFilteredProducts(products);
      return;
    }
    const filtered = products.filter(
      (product) =>
        product.productName.toLowerCase().includes(value.toLowerCase()) ||
        product.description.toLowerCase().includes(value.toLowerCase()),
    );
    setFilteredProducts(filtered);
  };

  const handleNextPage = () => {
    setPageNo((prevPageNo) => prevPageNo + 1);
  };

  const handlePreviousPage = () => {
    callFunc();
    setPageNo((prevPageNo) => (prevPageNo > 1 ? prevPageNo - 1 : 1));
  };

  const handlePageSizeChange = (event) => {
    setPageSize(Number(event.target.value));
  };

  return (
    <>
      <div className="font-poppins ">
        <Navbar
          showSearch={true}
          searchQuery={searchQuery}
          handleSearch={handleSearch}
          setSearchQuery={setSearchQuery}
        />
        {/* <ShopHero
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          handleSearch={handleSearch}
        /> */}

        <div className="container m-auto py-[6rem]">
          {loading ? (
            //  <div className="flex justify-center items-center h-screen">
            //   <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-24 w-24"></div>
            // </div>
            <Loader />
          ) : (
            <div className="prod-loop flex flex-wrap justify-center items-center lg:mt-[3rem] md:mt-[2rem] sm:mt-[0rem] xl:mt-[5rem]">
              {filteredProducts.map((product, index) => (
                <Link
                  key={index}
                  to={`/pp/${product.slug}`} // Link to product details screen
                  className="prod-wrapper mx-4 lg:mx-3 mt-10 w-full xl:w-[23%] lg:w-[22%] md:w-[44%] sm:w-1/2 cursor-pointer"
                >
                  <div className="img flex h-80 justify-center items-center text-center bg-[#EDEDED]">
                    <img
                      style={{ maxHeight: 300 }}
                      src={`https://storage.googleapis.com/awkat-o-salat/${product.listImagePath[0]}`}
                      alt={product.description}
                    />
                  </div>
                  <h4 className="prod-title text-[20px] italic font-semibold mt-3 text-[#273B60]">
                    {product.productName}
                  </h4>
                  <p className="prod-description text-[16px] text-[#273B60]">
                    {product.description}
                  </p>
                  <div className="prod-info flex justify-between items-center">
                    <div>
                      <div className="flex items-center">
                        <AiOutlineDollar className="text-lg text-[#273B60]" />
                        <h4 className="prod-title text-[20px] italic font-semibold mt-1 text-[#273B60] ml-2">
                          {`$${product.discountedPrice}.00`}
                          {product.discount > 0 && (
                            <span className="line-through text-[16px] text-[#a9a9a9] ml-2">
                              ${`${product.price}.00`}
                            </span>
                          )}
                        </h4>
                      </div>
                      <div className="flex">
                        {[...Array(5)].map((_, i) => (
                          <CiStar key={i} className="text-xl text-[#FDCE0C]" />
                        ))}
                      </div>
                      {/* <div className="flex items-center mt-1">
                        <FaBoxes className="text-sm text-[#a9a9a9]" />
                        <p className="text-sm text-[#a9a9a9] ml-2">
                          Quantity: {product.quantity}
                        </p>
                      </div>
                      <div className="flex items-center mt-1">
                        <GiCheckMark className="text-sm text-[#a9a9a9]" />
                        <p className="text-sm text-[#a9a9a9] ml-2">
                          Status: {product.status}
                        </p>
                      </div>
                      <div className="flex items-center mt-1">
                        <FaThumbsUp className="text-sm text-[#a9a9a9]" />
                        <p className="text-sm text-[#a9a9a9] ml-2">
                          Liked: {product.likedCount}
                        </p>
                      </div>
                      <div className="flex items-center mt-1">
                        <FaShoppingBag className="text-sm text-[#a9a9a9]" />
                        <p className="text-sm text-[#a9a9a9] ml-2">
                          Purchased: {product.purchasedCount}
                        </p>
                      </div> */}
                    </div>
                    {/* <div className="flex justify-center items-center relative top-0">
                      <div className="shadow-lg ml-6 hover:scale-105 bg-white rounded-full p-3">
                        <FaRegEye className="text-[#313131] text-lg" />
                      </div>
                      <div className="shadow-lg ml-6 hover:scale-105 bg-white rounded-full p-3">
                        <BiSolidCartAdd className="text-[#313131] text-lg" />
                      </div>
                    </div> */}
                  </div>
                </Link>
              ))}
            </div>
          )}
          <div className="pagination-controls mt-10 flex justify-center">
            <button
              onClick={handlePreviousPage}
              className="thm-btn theme-caret font-bold bg-[#273B60] transition-all duration-300 hover:scale-105 xl:text-lg lg:text-lg md:text-base sm:text-base text-[14px] rounded-lg btn xl:py-3 xl:px-12 lg:py-3 lg:px-10 md:py-2 md:px-12 sm:py-2 sm:px-8 py-1 text-white mx-2"
              disabled={pageNo === 1}
            >
              Previous
            </button>
            <button
              onClick={handleNextPage}
              className="thm-btn theme-caret font-bold bg-[#273B60] transition-all duration-300 hover:scale-105 xl:text-lg lg:text-lg md:text-base sm:text-base text-[14px] rounded-lg btn xl:py-3 xl:px-12 lg:py-3 lg:px-10 md:py-2 md:px-12 sm:py-2 sm:px-8 py-1 text-white mx-2"
            >
              Next
            </button>
          </div>
          {/* <div className="page-size-controls mt-10 flex justify-center">
            <label htmlFor="pageSize" className="mr-3 text-lg">
              Page Size:
            </label>
            <select
              id="pageSize"
              value={pageSize}
              onChange={handlePageSizeChange}
              className="border border-black rounded-lg p-2 text-lg"
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div> */}
        </div>

        <Footer />
      </div>
    </>
  );
};

export default Products;
