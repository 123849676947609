// Navbar.js
import React, { useState } from 'react';
import Logo from '../assests/tajam-logo.svg';
import HalfCircle from '../assests/navCircle.png';
import Circle from '../assests/cicle.png';
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
  FaUser,
  FaTimes,
} from 'react-icons/fa';
import { GiHamburgerMenu } from 'react-icons/gi';
import { navLinks } from '../constants/navData';
import { useDispatch, useSelector } from 'react-redux';
import { currentWebInterface } from '../store/reducers/main';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/authContext';
import HamburgerMenu from './HamburgerMenu';

const Navbar = ({ showSearch, searchQuery, handleSearch, setSearchQuery }) => {
  const [showNavLinks, setShowNavLinks] = useState(false);
  const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);

  const dispatch = useDispatch();
  const { webInterface } = useSelector((state) => state.main);
  const buttonText =
    webInterface === 'business' ? 'Become a Seller' : 'Become a Buyer';
  const navigate = useNavigate();
  const { isAuthenticated, logout } = useAuth();

  const toggleWebInterface = () => {
    const switchedInterface =
      webInterface === 'business' ? 'customer' : 'business';
    dispatch(currentWebInterface(switchedInterface));
    navigate(switchedInterface === 'business' ? '/' : '/business/analytics');
  };

  const navigateToLogin = () => {
    navigate('/login');
  };

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
    handleSearch(e.target.value);
  };

  const clearSearch = () => {
    setSearchQuery('');
    handleSearch('');
  };

  return (
    <div className="w-full pb-10 relative">
      {/* HAMBURGER MENU */}
      {showHamburgerMenu && (
        <HamburgerMenu setShowHamburgerMenu={setShowHamburgerMenu} />
      )}

      {/* NAVBAR */}
      <div className="flex fixed top-0 justify-center items-center w-full z-50">
        {/* HAMBURGER MENU ICON */}
        {isAuthenticated && (
          <div className="absolute  left-4 z-50">
            <GiHamburgerMenu
              onClick={() => setShowHamburgerMenu(!showHamburgerMenu)}
              className="text-[#F16427] text-2xl cursor-pointer hover:text-blue-700"
            />
          </div>
        )}

        {/* MAIN NAVBAR */}
        <div className="w-full z-10 h-[100%] shadow-navbarShadow bg-white flex justify-between items-center pl-2 pr-2 relative">
          {/* LOGO SECTION */}
          <div>
            <a href="/">
              <img src={Logo} alt="Logo" className="lg:h-20 h-8 lg:w-54" />
            </a>
          </div>

          {/* NAVLINKS SECTION */}
          <div className="sm:flex hidden gap-x-3 items-center p-0 bg-none">
            {navLinks?.map((item, index) => (
              <a href={item.link} key={index}>
                <p className="text-[#313131] hover:text-[#6d96e0] transition-all ease-in-out text-sm lg:text-base font-poppins cursor-pointer">
                  {item?.title}
                </p>
              </a>
            ))}
          </div>

          {/* RESPONSIVE NAVLINK SECTION */}
          {showNavLinks && (
            <div className="absolute right-0 top-2 block sm:hidden w-full pt-5 pb-5 pl-3 bg-white">
              {navLinks?.map((item, index) => (
                <a href={item.link} key={index}>
                  <p className="text-[#313131] mb-2 text-sm font-poppins cursor-pointer">
                    {item?.title}
                  </p>
                </a>
              ))}
            </div>
          )}

          {/* SOCIAL LINKS SECTION
          <div className="flex gap-x-3 items-center">
            <a
              href="https://www.facebook.com/tajammulapp/"
              className="hover:text-white hover:scale-110 transition-transform"
            >
              <FaFacebookF className="text-[#F16427] text-sm lg:text-lg cursor-pointer hover:text-blue-400" />
            </a>
            <a
              href="https://www.instagram.com/tajammul.app"
              className="hover:text-white hover:scale-110 transition-transform"
            >
              <FaInstagram className="text-[#F16427] text-sm lg:text-lg cursor-pointer hover:text-purple-500" />
            </a>
            <a
              href="https://twitter.com/TajammulApp"
              className="hover:text-white hover:scale-110 transition-transform"
            >
              <FaTwitter className="text-[#F16427] text-sm lg:text-lg cursor-pointer hover:text-blue-500" />
            </a>
            <a
              href="https://www.linkedin.com/company/tajammul-app/"
              className="hover:text-white hover:scale-110 transition-transform"
            >
              <FaLinkedinIn className="text-[#F16427] text-sm lg:text-lg cursor-pointer hover:text-blue-700" />
            </a>
          </div> */}

          {/* SEARCH INPUT SECTION */}
          {showSearch && (
            <div className="relative flex items-center w-full max-w-md">
              <input
                type="text"
                value={searchQuery}
                onChange={handleInputChange}
                className="border border-gray-300 rounded-md py-2 px-4 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Search..."
              />
              {searchQuery && (
                <button
                  onClick={clearSearch}
                  className="absolute right-2 text-gray-500 hover:text-gray-700"
                >
                  <FaTimes size={20} />
                </button>
              )}
            </div>
          )}
          {/* LOGIN BUTTON */}
          {!isAuthenticated && (
            <button
              onClick={navigateToLogin}
              className="text-[#F16427] text-sm lg:text-lg px-4 py-2 bg-white border border-[#F16427] rounded-md shadow-md hover:bg-[#F16427] hover:text-white focus:outline-none mb-1"
            >
              Login
            </button>
          )}
        </div>
      </div>

      {/* NAVBAR DECORATORS */}
      <div>
        {/* RIGHT CIRCLE */}
        {/* <div className="absolute right-0 top-0">
          <img src={Circle} alt="" className="md:h-[10rem] h-[6rem]" />
        </div> */}

        {/* LEFT CIRCLE */}
        {/* <div className="absolute left-0 top-0">
          <img src={HalfCircle} alt="" className="md:h-[13rem] h-[7rem]" />
        </div> */}
      </div>
    </div>
  );
};

export default Navbar;
