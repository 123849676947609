import { gql } from '@apollo/client';

export const CREATE_USER_MUTATION = gql`
  mutation CreateUser($userData: UserInput) {
    createUser(input: $userData) {
      message
      code
      status
      data {
        userId
      }
    }
  }
`;

export const FORGOT_PASSWORD_MUTATION = gql`
  mutation ForgotPassword($newPassword: String!, $user: String!) {
    forgotPassword(newPassword: $newPassword, user: $user) {
      status
    }
  }
`;

export const GET_REPORTS = gql`
  query GetReports(
    $metrics: [String!]!
    $dimensions: [String!]!
    $reportType: String!
    $propertyId: String!
    $startDate: String!
    $endDate: String!
  ) {
    getReports(
      data: {
        metrics: $metrics
        dimensions: $dimensions
        reportType: $reportType
        propertyId: $propertyId
        startDate: $startDate
        endDate: $endDate
      }
    ) {
      message
      code
      status
      data
    }
  }
`;

// Define the query to fetch business data for the user
export const GET_BUSINESS_BY_USER = gql`
  query {
    getBusinessByUser {
      message
      code
      status
      data {
        businessId
        isOpened
        businessInfo {
          propertyId
        }
      }
    }
  }
`;

export const GET_TASKS_BY_ID = gql`
  query GetTasksById($taskId: String!) {
    getTasksById(taskId: $taskId) {
      message
      code
      status
      data
    }
  }
`;

export const GET_PRODUCT_BY_IDENTIFIER = gql`
  query GetProductByIdentifier($identifier: String!) {
    getProductByIdentifier(identifier: $identifier) {
      status
      code
      message
      data {
        productName
        description
        quantity
        status
        price
        discount
        discountedPrice
        likedCount
        purchasedCount
        imagePaths
        listImagePath
        createdAt
        lastModifiedAt
        category {
          categoryName
        }
        tags {
          name
          category
        }

        business {
          createdAt
          lastModifiedAt
          isOpened
          isClaim
          timings {
            timings {
              monday {
                startTime
                endTime
              }
              tuesday {
                startTime
                endTime
              }
              wednesday {
                startTime
                endTime
              }
              thursday {
                startTime
                endTime
              }
              friday {
                startTime
                endTime
              }
              saturday {
                startTime
                endTime
              }
              sunday {
                startTime
                endTime
              }
            }
          }
          businessInfo {
            name
            imagePath
            listImagePath
            ntn
            salesTax
            currency
            businessTypes
            address {
              street
              postalCode
              additionalAddress
              residence
              area
              city
              province
              country
              countryCode
              latitude
              longitude
              formatedAddress
              addressName
              addressType
            }
          }
        }
      }
    }
  }
`;

export const CALCULATE_BUSINESSES_WITHIN_RANGE_QUERY = gql`
  query CalculateBusinessesWithinRangeByLocation(
    $latitude: Float!
    $longitude: Float!
    $pageNo: Int!
    $pageSize: Int!
  ) {
    calculateBusinessesWithinRangeByLocation(
      latitude: $latitude
      longitude: $longitude
      pageNo: $pageNo
      pageSize: $pageSize
    ) {
      message
      code
      status
      data {
        businesses {
          businessId
          isOpened
          isClaim
          businessDetails {
            inventoryList {
              products {
                productName
                slug
                description
                quantity
                status
                price
                discount
                discountedPrice
                likedCount
                purchasedCount
                imagePaths
                listImagePath
              }
              category {
                categoryName
              }
            }

            servicesList {
              serviceName
              imagePaths
              listImagePath
              description
              status
              price
              availableSlots
              discount
              discountedPrice
              likedCount
              purchasedCount
            }
          }
          businessInfo {
            name
            imagePath
            listImagePath
            address {
              street
              postalCode
              additionalAddress
              formatedAddress
              addressName
              residence
              area
              city
              province
              country
              latitude
              longitude
              addressType
              countryCode
            }
            currency
          }
          timings {
            timings {
              monday {
                startTime
                endTime
              }
              tuesday {
                startTime
                endTime
              }
              wednesday {
                startTime
                endTime
              }
              thursday {
                startTime
                endTime
              }
              friday {
                startTime
                endTime
              }
              saturday {
                startTime
                endTime
              }
              sunday {
                startTime
                endTime
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_TAGS_DATA = gql`
  query {
    getTagsData {
      status
      code
      message
      data {
        tagId
        name
        category
      }
    }
  }
`;

export const GET_PRODUCTS_BY_TAG = gql`
  query GetProductsByTag($tagIds: [String!]!) {
    getProductsByTag(tagIds: $tagIds) {
      status
      code
      message
      data {
        tagName
        products {
          productName
          slug
          description
          quantity
          status
          price
          discount
          discountedPrice
          likedCount
          purchasedCount
          imagePaths
          listImagePath
          createdAt
          lastModifiedAt
          category {
            categoryName
          }
          tags {
            name
            category
          }
          business {
            createdAt
            lastModifiedAt
            isOpened
            isClaim
            timings {
              timings {
                monday {
                  startTime
                  endTime
                }
                tuesday {
                  startTime
                  endTime
                }
                wednesday {
                  startTime
                  endTime
                }
                thursday {
                  startTime
                  endTime
                }
                friday {
                  startTime
                  endTime
                }
                saturday {
                  startTime
                  endTime
                }
                sunday {
                  startTime
                  endTime
                }
              }
            }
            businessInfo {
              name
              imagePath
              listImagePath
              ntn
              salesTax
              currency
              businessTypes
              currency
              address {
                street
                postalCode
                additionalAddress
                residence
                area
                city
                province
                country
                countryCode
                latitude
                longitude
                formatedAddress
                addressName
                addressType
              }
            }
          }
        }
      }
    }
  }
`;
