import React, { useState, useEffect } from 'react';
import prod1 from '../assests/prod-1.png';
import prod2 from '../assests/prod-2.png';
import prod3 from '../assests/prod-3.png';
import prod4 from '../assests/prod-4.png';
import { BiSolidCartAdd } from 'react-icons/bi';

import { CiStar } from 'react-icons/ci';
import { FaRegEye } from 'react-icons/fa';
import Navbar from './Navbar';
import Hero from './Hero';
// import { ProductModal } from "./ProductModal";

const BestDeals = () => {
  const [products] = useState([
    {
      id: 1,
      name: 'Extra Soft Touch Lamb Doll',
      regPrice: 899,
      salePrice: 599,
      category: 'Category 1',
      image: prod1,
      desc: 'Healthy by choice. Since 1984. Certified organic. USDA Organic. Certified organic by California Certified Organic Farmers (CCOF). Washed & ready to eat.',
      sizes: [
        { name: 'XXS', inStock: true },
        { name: 'XS', inStock: true },
        { name: 'S', inStock: true },
        { name: 'M', inStock: true },
        { name: 'L', inStock: true },
        { name: 'XL', inStock: true },
        { name: 'XXL', inStock: true },
        { name: 'XXXL', inStock: false },
      ],
    },
    {
      id: 2,
      name: 'Extra Soft Touch Lamb Doll',
      regPrice: 899,
      salePrice: 599,
      category: 'Category 2',
      image: prod2,
      desc: 'Healthy by choice. Since 1984. Certified organic. USDA Organic. Certified organic by California Certified Organic Farmers (CCOF). Washed & ready to eat.',
      sizes: [
        { name: 'XXS', inStock: true },
        { name: 'XS', inStock: true },
        { name: 'S', inStock: true },
        { name: 'M', inStock: true },
        { name: 'L', inStock: true },
        { name: 'XL', inStock: true },
        { name: 'XXL', inStock: true },
        { name: 'XXXL', inStock: false },
      ],
    },
    {
      id: 3,
      name: 'Extra Soft Touch Lamb Doll',
      regPrice: 899,
      salePrice: 599,
      category: 'Category 1',
      image: prod3,
      desc: 'Healthy by choice. Since 1984. Certified organic. USDA Organic. Certified organic by California Certified Organic Farmers (CCOF). Washed & ready to eat.',
      sizes: [
        { name: 'XXS', inStock: true },
        { name: 'XS', inStock: true },
        { name: 'S', inStock: true },
        { name: 'M', inStock: true },
        { name: 'L', inStock: true },
        { name: 'XL', inStock: true },
        { name: 'XXL', inStock: true },
        { name: 'XXXL', inStock: false },
      ],
    },
    {
      id: 4,
      name: 'Extra Soft Touch Lamb Doll',
      regPrice: 899,
      salePrice: 599,
      category: 'Category 3',
      image: prod4,
      desc: 'Healthy by choice. Since 1984. Certified organic. USDA Organic. Certified organic by California Certified Organic Farmers (CCOF). Washed & ready to eat.',
      sizes: [
        { name: 'XXS', inStock: true },
        { name: 'XS', inStock: true },
        { name: 'S', inStock: true },
        { name: 'M', inStock: true },
        { name: 'L', inStock: true },
        { name: 'XL', inStock: true },
        { name: 'XXL', inStock: true },
        { name: 'XXXL', inStock: false },
      ],
    },
  ]);
  // const [isModalOpen, setIsModalOpen] = useState(false);

  const [selectedProductId, setSelectedProductId] = useState(null);
  const [deliveryDate, setDeliveryDate] = useState(null);

  useEffect(() => {
    const today = new Date();

    const deliveryDate = new Date(today.getTime() + 5 * 24 * 60 * 60 * 1000);

    const deliveryDateString = `${formatDate(today)} - ${formatDate(
      deliveryDate,
    )}`;

    setDeliveryDate(deliveryDateString);
  }, []);

  const formatDate = (date) => {
    const options = { day: '2-digit', month: 'short' };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  };

  const [isprodOpen, setIsProdOpen] = useState(false);

  const productModal = (productId) => {
    setIsProdOpen(!isprodOpen);
    setSelectedProductId(productId);
  };
  return (
    <>
      <Navbar />
      <Hero />
      <div className="h-[100%] w-full  flex justify-center items-center mt-[8rem]">
        <div className="container">
          <div className="xl:flex container xl:w-full xl:nowrap md:flex-wrap xl:justify-between justify-center xl:items-center">
            <div className="col">&nbsp;</div>
            <div className="col">
              <h3 className="text-[#FDCE0C] xl:text-[60px] lg:text-[45px] md:text-[40px] text-[32px] text-center font-extrabold">
                Best Deals
              </h3>
              {/* <p className="text-[#BAB9B4] xl:text-[16px] lg:w-[65%] md:w-[60%] lg:text-[16px] md:text-[14px] sm:w-[70%] text-[12px] text-center xl:w-[80%] mx-auto">
                Scelerisque duis semper vitae eget mattis viverra sem risus vel
                suspendisse lorem vestibulum pulvinar habitant sed.
              </p> */}
            </div>
            <div className="col">
              <span className="px-5 xl:text-[18px] lg:text-[16px] text-[14px] cursor-pointer font-bold flex items-center xl:justify-end xl:m-0 mt-3 justify-center text-black">
                View More
              </span>
            </div>
          </div>

          <div className="prod-loop flex flex-wrap xl:flex-nowrap lg:flex-wrap md:flex md:flex-wrap sm:flex sm:flex-wrap justify-center items-center lg:mt-[3rem] md:mt-[2rem] sm:mt-[0rem]  xl:mt-[5rem]">
            {isprodOpen && (
              <div
                id="default-modal"
                className="fixed inset-0 w-[80%] mx-auto overflow-y-auto flex items-center justify-center z-50"
                role="dialog"
                aria-labelledby="modal-title"
                aria-modal="true"
              >
                <div className="relative bg-white rounded-lg shadow-lg w-full max-w-screen-80">
                  {/* Adjusted max-w class to set width to 80% of the screen */}
                  <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                    <p className="text-xl font-semibold">Quick View</p>
                    <button
                      onClick={productModal}
                      type="button"
                      className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg w-8 h-8 flex items-center justify-center"
                      aria-label="Close modal"
                    >
                      <svg
                        className="w-3 h-3"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 14 14"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="">
                    <div className="bg-white w-full  pr-4 pt-3 rounded-lg overflow-hidden z-50">
                      <div className="grid grid-cols-12">
                        <div className="xl:col-span-8 lg:col-span-8 md:col-span-12 sm:col-span-12 col-span-12 p-8">
                          <div className="grid grid-cols-12">
                            <div className="xl:col-span-5 lg:col-span-5 md:col-span-5 sm:col-span-12 col-span-12 ">
                              <div className="img flex h-80 justify-center items-center text-center px-6 bg-[#EDEDED]">
                                <img
                                  src={
                                    products.find(
                                      (product) =>
                                        product.id === selectedProductId,
                                    ).image
                                  }
                                  alt={
                                    products.find(
                                      (product) =>
                                        product.id === selectedProductId,
                                    ).name
                                  }
                                />
                              </div>
                            </div>
                            <div className="xl:col-span-7 lg:col-span-7 md:col-span-7 sm:col-span-12 col-span-12  xl:pl-4 lg:pl-4 md:pl-4 sm:pl-0 pl-0">
                              <h4 className="xl:text-[28px] lg:text-[26px] md:text-[22px] sm:text-[22px] text-[18px] font-semibold mt-3 mb-4">
                                {
                                  products.find(
                                    (product) =>
                                      product.id === selectedProductId,
                                  ).name
                                }
                              </h4>

                              <p className="desc">
                                <h4 className="xl:text-[18px] lg:text-[18px] md:text-[16px] text-[14px] font-semibold text-black-50">
                                  Details
                                </h4>

                                <p className=" text-[#00000099] text-[14px] mt-1 ">
                                  {
                                    products.find(
                                      (product) =>
                                        product.id === selectedProductId,
                                    ).desc
                                  }
                                </p>
                              </p>
                              <hr className="my-3" />
                              {/* <h4 className="xl:text-[18px] lg:text-[18px] md:text-[16px] text-[14px] font-semibold text-black-50">
                                Ingredients
                              </h4> */}
                              <hr className="my-3" />
                              {/* <h6 className="xl:text-[28px] text-[18px] text-[#F16427] font-semibold">
                                Rs.{' '}
                                {
                                  products.find(
                                    (product) =>
                                      product.id === selectedProductId,
                                  ).salePrice
                                }
                              </h6> */}
                              <div className="flex items-center">
                                {/* <h6 className="text-[#00000099] xl:text-[18px] text-[18px]">
                                  Rs.{' '}
                                  {
                                    products.find(
                                      (product) =>
                                        product.id === selectedProductId,
                                    ).regPrice
                                  }
                                </h6> */}
                                <span className="perc xl:ml-3 xl:mt-1  items-center text-black">
                                  -
                                  {selectedProductId &&
                                    products.find(
                                      (product) =>
                                        product.id === selectedProductId,
                                    ) &&
                                    (products.find(
                                      (product) =>
                                        product.id === selectedProductId,
                                    ).regPrice !== 0
                                      ? (
                                          (products.find(
                                            (product) =>
                                              product.id === selectedProductId,
                                          ).salePrice /
                                            products.find(
                                              (product) =>
                                                product.id ===
                                                selectedProductId,
                                            ).regPrice) *
                                          100
                                        ).toFixed(0) + '%'
                                      : 'N/A')}
                                </span>
                              </div>
                              <hr className="my-3 " />
                              {/* <h4 className="xl:text-[18px] lg:text-[18px] md:text-[16px] text-[14px] mb-3 text-black-50">
                                Size Family
                              </h4> */}
                              <div className="flex flex-wrap gap-2">
                                {selectedProductId && (
                                  <>
                                    {products
                                      .find(
                                        (product) =>
                                          product.id === selectedProductId,
                                      )
                                      .sizes.map((size, index) => (
                                        <button
                                          key={index}
                                          className="xl:text-[16px] lg:text-[16px] md:text-[16px] text-[14px] bg-white transition-all  duration-100 hover:scale-105 border shadow-md hover:shadow-xl  hover:text-[#F16427] text-black font-bold py-2 px-4 rounded"
                                        >
                                          {size.name}
                                        </button>
                                      ))}
                                  </>
                                )}
                              </div>
                              <div className="qty my-5">
                                <h4 className="xl:text-[18px] lg:text-[18px] md:text-[16px] text-[14px] mb-3 text-black-50">
                                  Quantity
                                </h4>
                                <div class="relative flex items-center max-w-[8rem]">
                                  <button
                                    type="button"
                                    id="decrement-button"
                                    data-input-counter-decrement="quantity-input"
                                    class="bg-gray-100  hover:bg-gray-200 border border-gray-300 rounded-s-lg p-3 h-11 focus:ring-gray-100  focus:ring-2 focus:outline-none"
                                  >
                                    <svg
                                      class="w-3 h-3 text-gray-900 "
                                      aria-hidden="true"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 18 2"
                                    >
                                      <path
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M1 1h16"
                                      />
                                    </svg>
                                  </button>
                                  <input
                                    type="text"
                                    id="quantity-input"
                                    data-input-counter
                                    data-input-counter-min="1"
                                    data-input-counter-max="50"
                                    aria-describedby="helper-text-explanation"
                                    class="bg-gray-50 border-x-0 border-gray-300 h-11 text-center text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full py-2.5 "
                                    placeholder="999"
                                    value="5"
                                    required
                                  />
                                  <button
                                    type="button"
                                    id="increment-button"
                                    data-input-counter-increment="quantity-input"
                                    class="bg-gray-100  hover:bg-gray-200 border border-gray-300 rounded-e-lg p-3 h-11 focus:ring-gray-100  focus:ring-2 focus:outline-none"
                                  >
                                    <svg
                                      class="w-3 h-3 text-gray-900"
                                      aria-hidden="true"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 18 18"
                                    >
                                      <path
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M9 1v16M1 9h16"
                                      />
                                    </svg>
                                  </button>
                                </div>
                              </div>
                              <div className="cta-btns flex justify-start gap-6">
                                <button className="hover:animate-bounce text-[12px] py-2 px-4 sm:py-2 sm:text-[12px] sm:px-3  xl:text-[20px]  lg:text-[12px] md:px-5 md:py-2 md:font-semibold  lg:px-7 lg:py-3 transition-all duration-300 theme-buybtn font-bold text-sm relative bg-[#273B60] rounded-lg btn xl:py-3 xl:px-7 text-white">
                                  Buy Now
                                </button>
                                <button className="hover:animate-bounce text-[12px] py-2 px-4  sm:py-2 sm:text-[12px] sm:px-3 xl:text-[20px] lg:text-[12px] md:px-5 md:py-2 md:font-semibold  lg:px-7 lg:py-3  theme-cartbtn font-bold text-sm relative bg-[#D1822D] rounded-lg btn xl:py-3 xl:px-7 text-white">
                                  Add to Cart
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="xl:col-span-4 lg:col-span-4 md:col-span-12 sm:col-span-12 col-span-12 pt-4 pb-10">
                          <div className="xl:ml-0 lg:ml-0 md:ml-4 sm:ml-4 ml-4  bg-[#F6F6F6] xl:mt-5 p-4 pl-6">
                            <h4 className="text-[#949494] xl:text-base">
                              Delivery
                            </h4>
                            <div className="flex justify-between">
                              <span className="address w-[60%]">
                                Sindh, Karachi - Gulshan-e-Iqbal, Block 15
                              </span>
                              <span className="address-change">
                                <button className="text-base cursor-pointer font-semibold text-[#273B60]">
                                  CHANGE
                                </button>
                              </span>
                            </div>
                            <hr className="my-3 bdr-thick" />
                            <div className="flex justify-between">
                              <div>
                                <h4 className="xl:text-base">
                                  <span className="font-bold">
                                    Free Delivery
                                  </span>{' '}
                                  {deliveryDate}
                                  <span className="text-[#949494] mt-3 block xl:text-base">
                                    5 - 10 day(s)
                                  </span>
                                </h4>
                              </div>
                              <h4 className="text-base">Free </h4>
                            </div>
                            <hr className="my-3 bdr-thick" />
                            <h4 className="text-[#949494] xl:text-base">
                              Cash on Delivery Available
                            </h4>
                            <hr className="my-3 bdr-thick" />
                            <p className="text-[#949494] mt-3 block xl:text-base">
                              Services
                            </p>
                            <p className="">14 days free & easy return</p>
                            <p className="text-[#949494]  block xl:text-base">
                              Change of mind is not acceptable
                            </p>
                            <p className="mt-3">Warranty not available</p>
                          </div>

                          <div className="mt-5 xl:ml-0 lg:ml-0 md:ml-4 sm:ml-4 ml-4  bg-[#F6F6F6]  ">
                            <div className="p-4 pl-6">
                              <h4 className="text-[#949494]  xl:text-base">
                                Delivery
                              </h4>
                              <div className="flex justify-between">
                                <span className="address w-[60%] font-semibold text-lg  store-owner">
                                  HKS TRADERS
                                </span>
                                <span className="address-change">
                                  <button className="text-base font-semibold cursor-pointer text-[#273B60]">
                                    CHAT
                                  </button>
                                </span>
                              </div>
                            </div>
                            <div className="flex justify-between xl:flex-row lg:flex-col md:flex-row flex-col">
                              <div className="border-r md:w-full  border-b text-center border-t p-5">
                                <p className="text-[#949494]  xl:text-sm lg:text-sm">
                                  Positive Seller Ratings
                                </p>
                                <h4 className="text-black font-semibold xl:text-2xl lg:text-sm">
                                  70%
                                </h4>
                              </div>
                              <div className="border-r md:w-full border-b text-center border-t p-5">
                                <p className="text-[#949494]  xl:text-sm lg:text-sm">
                                  Ship OnTime
                                </p>
                                <h4 className="text-black font-semibold xl:text-2xl lg:text-sm">
                                  86%
                                </h4>
                              </div>
                              <div className="border-r md:w-full border-b text-center border-t p-5">
                                <p className="text-[#949494]  xl:text-sm lg:text-sm">
                                  Chat Response Time
                                </p>
                                <h4 className="text-black font-semibold xl:text-2xl lg:text-sm">
                                  96%
                                </h4>
                              </div>
                            </div>
                            <div className="flex  justify-center py-4">
                              <span className="underline xl:text-lg cursor-pointer font-semibold text-[#273B60]">
                                VISIT STORE
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              // <ProductModal prodId={selectedProductId}/>
            )}

            {products.map((product) => (
              <div
                key={product.id}
                className="prod-wrapper  xl:mx-4 lg:mx-3 mx-8 lg:mt-10 w-full md:mt-10 sm:mt-5 mt-10 lg:w-3/12 md:w-[40%] sm:w-[80%] sm:mx-auto cursor-pointer xl:w-3/12"
              >
                <div className="img flex h-80 justify-center items-center text-center bg-[#EDEDED]">
                  <img src={product.image} alt={product.name} />
                </div>
                <h4 className="prod-title xl:text-[20px] text-[18px] italic font-semibold mt-3 text-[#273B60]">
                  {product.name}
                </h4>
                <div className="prod-info flex justify-between items-center">
                  <div>
                    <h4 className="prod-title xl:text-[20px] text-[18px] italic  font-semibold mt-1 text-[#273B60]">
                      ${`${product.salePrice}.00`}
                    </h4>
                    <div className="flex">
                      <CiStar className="text-xl text-[#FDCE0C]" />
                      <CiStar className="text-xl text-[#FDCE0C]" />
                      <CiStar className="text-xl text-[#FDCE0C]" />
                      <CiStar className="text-xl text-[#FDCE0C]" />
                      <CiStar className="text-xl text-[#FDCE0C]" />
                    </div>
                  </div>
                  <div className="flex justify-center items-center relative xl:top-3 top-0">
                    <div
                      onClick={() => productModal(product.id)}
                      className="shadow-lg ml-6 hover:scale-105 bg-white rounded-full p-3"
                    >
                      <FaRegEye className="text-[#313131] text-lg" />
                    </div>
                    <div className="shadow-lg ml-6 hover:scale-105 bg-white rounded-full p-3">
                      <BiSolidCartAdd className="text-[#313131] text-lg" />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="h-[100%] w-full  flex justify-center items-center mt-[8rem]">
        <div className="container">
          <div className="xl:flex container xl:w-full xl:nowrap md:flex-wrap xl:justify-between justify-center xl:items-center">
            {/* <div className="container"> */}
            <div className="col">&nbsp;</div>
            <div className="col">
              <h3 className="text-[#F48150] leading-tight xl:text-[60px] lg:text-[45px] md:text-[40px] text-[32px] text-center font-extrabold">
                Popular Products
              </h3>
              {/* <p className="text-[#BAB9B4] xl:text-[16px] lg:w-[65%] md:w-[60%] lg:text-[16px] md:text-[14px] sm:w-[70%] text-[12px] text-center xl:w-[80%] mx-auto">
                Scelerisque duis semper vitae eget mattis viverra sem risus vel
                suspendisse lorem vestibulum pulvinar habitant sed.
              </p> */}
            </div>
            <div className="col">
              <span className="px-5 xl:text-[18px] lg:text-[16px] text-[14px] cursor-pointer font-bold flex items-center xl:justify-end xl:m-0 mt-3 justify-center text-black">
                View More
              </span>
            </div>
            {/* </div> */}
          </div>

          <div className="prod-loop flex flex-wrap xl:flex-nowrap lg:flex-wrap md:flex md:flex-wrap sm:flex sm:flex-wrap justify-center items-center mt-[5rem]">
            {products.map((product) => (
              <div
                key={product.id}
                className="prod-wrapper  xl:mx-4 lg:mx-3 mx-8 lg:mt-10 w-full md:mt-10 sm:mt-10 mt-10 lg:w-3/12 md:w-[40%] sm:w-[80%] sm:mx-auto cursor-pointer xl:w-3/12"
              >
                <div className="img flex h-80 justify-center items-center text-center bg-[#EDEDED]">
                  <img src={product.image} alt={product.name} />
                </div>
                <h4 className="prod-title italic text-xl font-semibold mt-3 text-[#273B60]">
                  {product.name}
                </h4>
                <div className="prod-info flex justify-between items-center">
                  <div>
                    <h4 className="prod-title italic text-lg font-semibold mt-1 text-[#273B60]">
                      ${`${product.salePrice}.00`}
                    </h4>
                    <div className="flex">
                      <CiStar className="text-xl text-[#FDCE0C]" />
                      <CiStar className="text-xl text-[#FDCE0C]" />
                      <CiStar className="text-xl text-[#FDCE0C]" />
                      <CiStar className="text-xl text-[#FDCE0C]" />
                      <CiStar className="text-xl text-[#FDCE0C]" />
                    </div>
                  </div>
                  <div className="flex justify-center items-center relative top-3">
                    <div
                      onClick={() => productModal(product.id)}
                      className="shadow-lg ml-6 hover:scale-105 bg-white rounded-full p-3"
                    >
                      <FaRegEye className="text-[#313131] text-lg" />
                    </div>
                    <div className="shadow-lg ml-6 hover:scale-105 bg-white rounded-full p-3">
                      <BiSolidCartAdd className="text-[#313131] text-lg" />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="h-[100%] w-full  flex justify-center items-center mt-[8rem] pb-[12rem]">
        <div className="container">
          <div className="xl:flex container xl:w-full xl:nowrap md:flex-wrap xl:justify-between justify-center xl:items-center">
            {/* <div className="container"> */}
            <div className="col">&nbsp;</div>
            <div className="col">
              <h3 className="text-[#213252] leading-tight xl:text-[60px] lg:text-[45px] md:text-[40px] text-[32px] text-center font-extrabold">
                Top Selling
              </h3>
              {/* <p className="text-[#BAB9B4] xl:text-[16px] lg:w-[65%] md:w-[60%] xl:mt-0 lg:mt-0 md:mt-0 sm:mt-0 mt-3 lg:text-[16px] md:text-[14px] sm:w-[70%] text-[12px] text-center xl:w-[80%] mx-auto">
                Scelerisque duis semper vitae eget mattis viverra sem risus vel
                suspendisse lorem vestibulum pulvinar habitant sed.
              </p> */}
            </div>
            <div className="col">
              <span className="px-5 xl:text-[18px] lg:text-[16px] text-[14px] cursor-pointer font-bold flex items-center xl:justify-end xl:m-0 mt-3 justify-center text-black">
                View More
              </span>
            </div>
            {/* </div> */}
          </div>

          <div className="prod-loop flex flex-wrap xl:flex-nowrap lg:flex-wrap md:flex md:flex-wrap sm:flex sm:flex-wrap justify-center items-center mt-[5rem]">
            {products.map((product) => (
              <div
                key={product.id}
                className="prod-wrapper  xl:mx-4 lg:mx-3 mx-8 lg:mt-10 w-full md:mt-10 sm:mt-10 mt-10 lg:w-3/12 md:w-[40%] sm:w-[80%] sm:mx-auto cursor-pointer xl:w-3/12"
              >
                <div className="img flex h-80 justify-center items-center text-center bg-[#EDEDED]">
                  <img src={product.image} alt={product.name} />
                </div>
                <h4 className="prod-title italic text-xl font-semibold mt-3 text-[#273B60]">
                  {product.name}
                </h4>
                <div className="prod-info flex justify-between items-center">
                  <div>
                    <h4 className="prod-title italic text-lg font-semibold mt-1 text-[#273B60]">
                      ${`${product.price}.00`}
                    </h4>
                    <div className="flex">
                      <CiStar className="text-xl text-[#FDCE0C]" />
                      <CiStar className="text-xl text-[#FDCE0C]" />
                      <CiStar className="text-xl text-[#FDCE0C]" />
                      <CiStar className="text-xl text-[#FDCE0C]" />
                      <CiStar className="text-xl text-[#FDCE0C]" />
                    </div>
                  </div>
                  <div className="flex justify-center items-center relative top-3">
                    <div className="shadow-lg ml-6 hover:scale-105 bg-white rounded-full p-3">
                      <FaRegEye className="text-[#313131] text-lg" />
                    </div>
                    <div className="shadow-lg ml-6 hover:scale-105 bg-white rounded-full p-3">
                      <BiSolidCartAdd className="text-[#313131] text-lg" />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default BestDeals;
